<template>
  <span
    component-name="txt-geodate"
    class="whitespace-nowrap min-w-min max-w-min tabular-nums"
    v-html="computedDate"
  ></span>
</template>

<script setup>
const props = defineProps({
  unix: {
    type: [Number, String],
    required: true,
  },
});

const { getLocalDate } = useLocalDate();

const computedDate = computed(() => {
  return getLocalDate(props.unix, undefined);
});
</script>
