// https://www.techonthenet.com/js/language_tags.php
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument

import parser from "accept-language-parser";

export const useLocalDate = () => {
  function getLocalDate(
    unix,
    timezone,
    options = { year: "numeric", month: "long", day: "numeric" }
  ) {
    const event = new Date(unix * 1000);
    const { params } = useRoute();

    let detectedLang = "";
    let lang = params.locale;

    if (process.client) {
      detectedLang = window.navigator.language;
    } else {
      const acceptLanguage = useRequestHeaders(["accept-language"]);
      const detectedLanguages = parser.parse(acceptLanguage["accept-language"]);
      detectedLang = detectedLanguages;
    }

    // detectedLang = "en-US";
    // lang = "it";

    let format = event.toLocaleDateString(lang, options);

    const months = {
      en: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      it: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      fr: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      de: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      es: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Deciembre",
      ],
      zh: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
    };

    // find which substring in all is contained in format and return it.
    const findSubstr = (str) => {
      for (let i = 0; i < all.length; i++) {
        if (str.toLowerCase().includes(all[i].toLowerCase())) {
          return all[i];
        }
      }
    };

    const all = Object.values(months).flat();

    if (/en-US/.test(detectedLang)) {
      format = event.toLocaleDateString(detectedLang, options);
      format = format.replace(
        findSubstr(format),
        months[lang][event.getMonth()]
      );
    }

    return format;
  }

  return {
    getLocalDate,
  };
};
